// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fade-out {
  animation: fadeinout .3s linear forwards;
}

@keyframes fadeinout {
  0% { opacity: 0.6; }
  50% { opacity: 0.2; }
  100% { opacity: 0; }
}

.fade-in {
  animation: display 6s linear forwards;
}

@keyframes display {
  0% { opacity: 0; }
  40% { opacity: 0; }  
  50% { opacity: 0.1; }
  100% { opacity: 1; }
}

.message {
  width: 60%;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EAEE,wCAAwC;AAC1C;;AAQA;EACE,KAAK,YAAY,EAAE;EACnB,MAAM,YAAY,EAAE;EACpB,OAAO,UAAU,EAAE;AACrB;;AAEA;EAEE,qCAAqC;AACvC;;AAUA;EACE,KAAK,UAAU,EAAE;EACjB,MAAM,UAAU,EAAE;EAClB,MAAM,YAAY,EAAE;EACpB,OAAO,UAAU,EAAE;AACrB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.fade-out {\n  -webkit-animation: fadeinout .3s linear forwards;\n  animation: fadeinout .3s linear forwards;\n}\n\n@-webkit-keyframes fadeinout {\n  0% { opacity: 0.6; }\n  50% { opacity: 0.2; }\n  100% { opacity: 0; }\n}\n\n@keyframes fadeinout {\n  0% { opacity: 0.6; }\n  50% { opacity: 0.2; }\n  100% { opacity: 0; }\n}\n\n.fade-in {\n  -webkit-animation: display 6s linear forwards;\n  animation: display 6s linear forwards;\n}\n\n\n@-webkit-keyframes display {\n  0% { opacity: 0; }\n  40% { opacity: 0; }\n  50% { opacity: 0.1; }\n  100% { opacity: 1; }\n}\n\n@keyframes display {\n  0% { opacity: 0; }\n  40% { opacity: 0; }  \n  50% { opacity: 0.1; }\n  100% { opacity: 1; }\n}\n\n.message {\n  width: 60%;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
