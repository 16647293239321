import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <marquee scrollamount="40">
          <div className={"comic-sans multicolor-text"}>Website design is my passion</div>
	</marquee>
      <div className="fade-in message">
	In all seriousness, this is just some placeholder html/css.
	This is a new project and I'm still setting up the site. You can expect frequent updates to this site in the next couple weeks.</div>
      </header>
    </div>
  );
}

export default App;
